.navbar {
    background-color: #051133;

    height: 70px;

    .navbar-brand {
        img {
            height: 50px;
        }
    }

    .navbar-collapse {
        .logged-as {
            display: flex;

            .name {
                margin: auto;
                margin-right: .75rem;
                font-weight: 600;
            }

            img.photo {
                height: 40px;
                width: 40px;
                object-fit: cover;
                border-radius: 50%;       
            }
        }

        .log-out {
            display: flex;
            
            background: none;
            border: none;

            margin-left: 1rem;
            
            color: #B6B6E2;

            span {
                margin: auto;
            }

            .bi {
                font-size: 1.25rem;
                margin-left: .25rem;
            }

            &:hover, &:active, &:focus {
                color: var(--bs-light);
            }
        }
    }
}

