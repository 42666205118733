.Empreendimento {
    height: 100%;

    .toast {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 1rem;
    }
    
    .row {
        height: 100%;
        width: 100%;
        margin: 0;
    }

    .unidades {
        padding: 0;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        position: relative;
        
        .heading {
            padding: 1rem 1.5rem;
            border-bottom: solid 1px #A6ACBD;
        }

        .selection { 

            .heading {
                padding-top: .5rem;
                > .title {
                    svg {
                        height: 30px;
                    }
                }
            }
            
            .cotas {

                .cotas-title {
                    padding: .5rem 1.5rem;
                    padding-bottom: 0;

                    h3, h4, h5, h6 { 
                        margin: 0;
                    }
                }
            }
    
            .back-button {
                display: inline;
                cursor: pointer;
                color: #7F8298;
                font-size: .9rem
            }

            .CotasCabana {
                height: 133px;
            }
        }

        .no-selection { 

            .limpar {
                cursor: pointer;
                color: #7F8298;
                border: solid 1px #7F8298;
                background-color: transparent;
                font-size: .875rem;
                padding: .25rem 1rem;

                &:hover, &:active, &:focus {
                    background-color: #f0f1f5;
                }
            }

            .ListCabanas {
                height: 395px;
            }
        }

        .overview {
            background-color: #E5E7ED;
            padding: .5rem 2rem .5rem 1.5rem;
            
            width: 100%;
            height: 65px;

            &.disabled {
                opacity: .5;
            }

            .price {
                margin: auto;
                margin-left: 0;
                color: #051133;
                font-size: 1.5rem;
                font-weight: 600;
            }

            .action {
                margin: auto;
                margin-right: 0;

                .btn {
                    padding: .5rem 1.5rem;
                }
            }
        }
        
    }

    .toast {
        z-index: 1000;
    }

    .mapa {
        position: relative;
        padding: 0;
        background-color: #6d8530;

        .count-wrap {
            position: absolute;
            right: 0;
            top: 0;
            margin: 1rem;

            .pdf {
                background-color: white;
                padding: .5rem;
                border-radius: 5px;
                a {
                    font-size: 1.1rem;
                }
            }

            .count {
                background-color: white;
                padding: .5rem;
                border-radius: 5px;
                color: #4C516F;
                font-size: .9rem;
                
                .count-number {
                    display: inline-flex;
                    height: 30px;
                    width: 30px;
                    padding: .25rem;
                    background-color: #10109E; 
                    
                    &.zero {
                        background-color: #7F8298; 
                    }

                    span {
                        margin: auto;
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: white;
                        margin-top: -.16rem;
                    }
                }
            }
        }
    }
}