.Proposta {
    height: 100%;
    display: flex;

    > .row {
        flex: 1 1 auto;
        height: 100%;
    }

    .form-col {
        padding: 1rem 3rem;

        .form-inner {
            max-width: 600px;
        }

        .back-button {
            display: inline;
            cursor: pointer;
            color: #7f8298;
            font-size: 0.9rem;
            text-decoration: none;
        }

        .progress-bars {
            .stage-bar {
                display: block;
                width: 100%;
                height: 10px;
                margin: 0 0.25rem;
                background-color: #e5e7ed;

                position: relative;
                overflow: hidden;

                &:first-child {
                    margin-left: 0;
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    margin-right: 0;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    inset: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #ffc038 0%, #ff6d13 100%);
                    left: -100%;
                }

                &.previous {
                    &::before {
                        left: 0;
                    }
                }

                &.active {
                    &::before {
                        animation-name: slideIn;
                        animation-duration: 0.75s;
                        animation-timing-function: ease;
                        animation-delay: 0.1s;
                        animation-fill-mode: forwards;
                    }
                }

                @keyframes slideIn {
                    from {
                        left: -100%;
                    }

                    to {
                        left: 0;
                    }
                }
            }
        }

        .stage-view {
            padding-top: 1rem;
            color: #051133;

            .form-section {
                margin-left: 1rem;

                .section-content {
                    border-left: solid 3px #e5e7ed;
                    padding-left: 1rem;

                    .form-section {
                        margin-left: 0;
                    }
                }
            }

            .review-section {
                margin-left: 1rem;
                width: 450px;

                .field-row {
                    display: flex;
                    > * {
                        margin-right: 2.25rem;
                    }
                }

                .field-view {
                    margin-bottom: 0.75rem;

                    small {
                        font-size: 0.875rem;
                        margin-bottom: 0.25rem;
                        color: #4c516f;
                    }

                    p {
                        margin-top: -0.25rem;
                        margin-bottom: 0;
                        font-weight: 600;
                        color: #051133;
                    }
                }
            }

            .form-label {
                font-weight: 600;
                font-size: 0.875rem;
                margin-bottom: 0.25rem;
            }

            .form-control,
            .form-select {
                border: solid 1px #b6b6e2;
                border-radius: 5px;

                &::placeholder {
                    color: #4c516f;
                }
            }

            .form-row {
                margin-bottom: 0.625rem;
            }
        }
    }

    .details-col {
        padding: 3rem 1.5rem 1rem 2rem;
        background-color: #e5e7ed;
        height: 100%;

        > .title {
            color: #051133;
            margin-bottom: 1rem;
        }

        .brief {
            > .title {
                margin: 0;
                color: #4c516f;
                font-size: 0.9rem;
            }

            .brief-section {
                .brief-title {
                    color: #051133;
                    font-weight: 600;
                    margin: 0.25rem 0 0;
                }

                .brief-section {
                    color: #4c516f;
                    margin-left: 1rem;
                    .nome-cota {
                        font-weight: 600;
                    }
                }
            }

            .total-price {
                color: #051133;
                font-weight: 600;
                font-size: 1.1rem;
            }
        }
    }

    button.not-approved {
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    .rotating {
        animation: rotating 1s linear infinite;
    }
}
