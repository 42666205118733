h1 {
    font-weight: 600 !important;
}

body,
html {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-flow: column;
    height: 100%;

    .Header {
        // flex: 0 1 auto;
        
        .navbar {
            &-brand {
                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }

    .App {
        flex: 1 1 auto;
    }
}
