.Empreendimentos {
    .title {
        color: #001659;
    }

    .emp-card {
        width: 100%;
        position: relative;

        border-radius: 20px;
        overflow: hidden;
        
        img.cover {
            width: 100%;
            height: 350px;
            object-fit: cover;
        }

        .overlay {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(rgba(white, 0) 50%, rgba(white, .8));
            display: flex;

            .logo { 
                width: 100%;
                img {
                    margin-left: 50px;
                    height: 150px;
                    width: auto;
                }
            }

            .button {
                display: flex;
                width: 100%;

                .btn {
                    margin: auto 30px 15px auto;
                }
            }
        }
    }
}