
.Login {

    .present {
        .cover-img {
            height: 100vh;
            width: 100%;
            object-fit: cover;
        }
    }
    
    .form-wrap {
        padding: 20px 40px;
        width: 100%;

        h1 {
            margin-bottom: 1rem;
        }

        form {
            .form-label {
                color: #4C516F;
                font-weight: 600;
                font-size: .9rem;
                margin-bottom: .25rem;
            } 

            .form-control {
                border: 1px solid #B6B6E2;
                border-radius: 5px;
                color: rgba(#001659, 1);

                &::placeholder {
                    color: rgba(#001659, .3);
                }
            }

            .forget {
                color: #4C516F;
                margin-right: 1.5rem;
                text-decoration: none;

                &:hover, &:active, &:focus {
                    text-decoration: underline;
                }
            }

            .submit {
                padding: 7px 20px;
            }
        }
    }
}