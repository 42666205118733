.CotasCabana {
    overflow-y: scroll;
    padding: 0 1rem .5rem 1.5rem;
    
    .cotas-row {

        .cota-option-wrap {
            padding: .25rem;
        }

        .cota-option {
            padding: .25rem;

            position: relative;
    
            border-radius: 5px;
            overflow: hidden;

            border: solid 1px #E5E7ED;

            cursor: pointer;

            &:hover, &:active, &:focus {
                background-color: #f0f1f5;
            }

            .numero {
                color: #4C516F;
                font-size: 1.1rem;
                
                font-weight: 600;
                text-align: center;

                min-height: 2.6rem;
                min-width: 1.1rem;
                display: flex;
                flex-flow: column;

                .text {
                    margin: auto;
                }

                .icon {
                    display: none;

                    margin: auto;
                    margin-top: -10px;
                }
            }

            .datas {
                // margin-left: 0;
                color: #7F8298;
                font-size: .875rem;
            }

            &.selected {
                background-color: #24C34F;
                color: white;

                .numero {
                    line-height: 1.6rem;
                    // display: block;
                }

                * { 
                    color: inherit;
                }

                .icon {
                    display: block;
                }
            }

            .over-text {
                display: none;

                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                text-transform: uppercase;
                
                span {
                    font-size: .85rem;
                    line-height: 1;

                    margin: auto;
                    color: white;
                    font-weight: 600;
                }
            }

            &.cota-vendida {
                cursor: default;
                
                .over-text {
                    display: flex;
                    background: rgba(#E3591C, .9);
                }
            }

            &.cota-reservada {
                cursor: default;
                
                .over-text {
                    display: flex;
                    background: rgba(#E2A023, .9);
                }
            }
        }

    }

}