.ListCabanas {
    overflow-y: scroll;

    .unidade-card {
        border-bottom: solid 1px #E5E7ED;
        padding: 1rem;

        display: flex;

        cursor: pointer;
        transition: background .2s;

        &:hover, &:active, &:focus {
            background: #f0f1f5;
        } 

        > * {
            margin: auto;
        }

        &.muted {
            .foto, .info {
                opacity: .5;
            }
        }

        .foto {
            position: relative;
            
            img {
                height: 55px;
                width: 55px;
                object-fit: cover;
                border-radius: 50%;
            }

            .icon {
                display: none;

                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;

                background-color: rgba(#24C34F, .5);

                span {
                    color: white;
                    margin: auto;
                }
            }

            &.selected {
                .icon {
                    display: flex;
                }
            }
        }

        .info {
            flex: 1 1 auto;

            padding: 0 1rem;
            .nome {
                font-weight: 600;
                font-size: 1.1rem;
                color: #051133;
            }

            .caracter {
                font-size: .875rem;
                color: #4C516F;
            }

            .valor-base {
                font-weight: 600;
                color: #4C516F;
            }
        }
        .tag{
            margin: auto;

            span {
                border: solid 1px black;
                padding: .25rem .5rem;
                border-radius: 5px;
                text-transform: uppercase;

                &.disponivel {
                    color: #7A913C;
                    border-color: #7A913C;
                }

                &.vendida {
                    color: #E3591C;
                    border-color: #E3591C;
                }
            }
        }
    }
}